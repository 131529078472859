/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:73f96e85-fb02-4f86-8237-23bd44a5a51d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ccyUAHSk4",
    "aws_user_pools_web_client_id": "119emltg7e8vlcfdidb6htiv1v",
    "sso_auth_key": "r1QvbYiJEC904HjRls7Uf4M8OgaBQ2iM3tjnPCuv",
    "oauth": {},
    "aws_content_delivery_bucket": "tillpayments-uat-us-east-1-website",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://tillpayments-uat.merchant-dispute.com"
};


export default awsmobile;
