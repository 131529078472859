export const clientTheme = {
    mainLayout: {
        backgroundColor: '#fafaf9',
        hover: {
            backgroundColor: '#ff591e',
        },
    },
    topBar: {
        backgroundColor: '',
        contrastText: '',
        contrastLinkText: '#098fe2',
        userIcon: 'red',
        brandLogo: {
            maxWidth: '160px',
            paddingTop: '',
        },
        menuBarIcon: {
            color: '#000',
        },
    },
    sidebar: {
        fontSize: '14px',
        backgroundColor: '#333436',
        darkBackgroundColor: '#1f2024',
        sideMenuActive: {
            backgroundColor: '#ff591e',
            contrastText: '',
        },
        sideMenuHover: {
            backgroundColor: '#ff591e',
            contrastText: '',
        },
    },
    footer: {
        backgroundColor: '',
        contrastText: '',
    },
    charts: {
        fill: '#EF9A9A',
        stroke: '#ff0000',
        lineChart: {
            stroke: '#ff0000',
            fill: '#ff0000',
        },
        areaChart: {
            stroke: '#ff0000',
            fill: '#EF9A9A',
            chargebacksByPostDate: {
                stroke: '#ff0000',
                fill: '#EF9A9A',
            },
        },
        barChart: {
            fill: '#ff0000',
            byPlatformBarChart: '#8D6492',
            byBinBarChart: '#C5A000',
        },
        lineBarCharts: {
            byNonUniqueCustomerLineBarChart: {
                barColor: 'rgb(54, 119, 176)',
                lineColor: '#cb2022',
            },
        },
        pieCharts: {
            defaultPieChart: [
                'rgb(200, 0, 0)',
                'rgb(42, 126, 160)',
                'rgb(113, 78, 177)',
                '#FF8042',
            ],
            dashboardPieChart: [
                'rgb(230, 0, 0)',
                'rgb(255, 0, 0)',
                'rgb(185, 0, 0)',
                'rgb(150, 0, 0)',
            ],
            byReasonCodePieChart: [
                '#D94D4D',
                '#B40000',
                '#8C0000',
                '#FC0101',
                '#B43900',
            ],
            byReasonCodeDistributionPieChart: [
                '#707070',
                '#6620D9',
                '#856dfc',
                '#a38fdf',
                '#7161ac',
            ],
            byCardTypePieChart: ['#C80001', '#714FB1', '#F88D2B', '#2D80A1'],
            byLocationTypePieChart: ['rgb(54, 119, 176)', '#1f3563'],
            byPlatformPerCardTypePieChart: [
                '#CC4401',
                '#CC6600',
                '#f79d16',
                '#ebc400',
                '#8a7300',
            ],
            byStatusPieChart: [],
            byWinLossPieChart: [],
        },
    },
    lockAndUnlockButton: {
        lockButton: {},
        unlockButton: {},
    },
    lampColors: {
        green: '#57a537',
        red: '#cb2022',
        orange: '#d87707',
        yellow: '#d8d800',
        grey: 'grey',
    },
    uploadDnD: {
        borderColor: `#cb2022`,
        backgroundColor: `rgba(203,32,34,.1)`,
        labelColor: `#cb2022`,
    },
    metricTile: {
        arrow: {
            width: 26,
            height: 40,
        },
    },
    accentAreaTopBorderAccent: {
        backgroundColor: '#ff591e',
        borderRadius: '',
    },
    dateRangeContainer: {
        borderRadius: '',
        padding: '7px 8px',
    },
    selectionBox: {
        borderRadius: '4px',
    },
    formFields: {
        formLabel: {},
        formText: {
            standard: {
                color: '#263238',
            },
            outlined: {
                fontSize: '',
            },
        },
    },
    labelTags: {
        borderRadius: '16px',
        backgroundColor: '#e0e0e0',
    },
    searchBar: {
        padding: '6px',
    },
    buttons: {
        styleButton: {
            root: {
                textTransform: 'capitalize',
            },
            contained: {
                textTransform: 'capitalize',
            },
        },
        defaultButton: {
            variant: 'contained',
            style: {
                backgroundColor: '#E0E0E0',
                color: 'rgba(0, 0, 0, 0.87)',
                '&:hover': {
                    backgroundColor: '#d5d5d5',
                },
            },
        },
        textOrOutlinedButton: {
            variant: 'outlined',
            style: {
                border: '1px solid rgba(0, 0, 0, 0.23) !important',
                borderRadius: '4px !important',
                color: '#263238',
                '&:hover': {
                    backgroundColor: 'rgba(38, 50, 56, 0.04)',
                },
            },
        },
        greyButton: {
            variant: 'contained',
            style: {
                color: '#4f4f4f',
                backgroundColor: '#D5D5D5',
                '&:hover': {
                    color: '#4f4f4f',
                    backgroundColor: '#D5D5D5',
                },
            },
        },
        greenButton: {
            variant: 'contained',
            style: {
                backgroundColor: '#3ED4AF',
                '&:hover': {
                    backgroundColor: '#3ac5a2',
                },
            },
        },
        activeStateButton: {
            color: 'white',
            backgroundColor: '#61656E',
        },
    },
    icons: {
        uploadIcon: '#3cb4e5',
        dnrAndRevertDnrIcon: '#c80000',
        toggleUnlockIcon: '#75797b',
        toggleLockIcon: '#c80000',
        editUserIcon: '#2a7ea0',
        midPermissionIcon: '#714eb1',
        sitePermissionIcon: '#f88d2b',
        tempPasswordIcon: '#3cb4e5',
    },
    main: '#ff0000',
    primaryLight: '#ff591e',
    primary: '#EF9A9A',
    primaryDark: '#B71C1C',
    secondaryLight: '#ff591e',
    secondary: '#3cb4e5',
    secondaryDark: '#17A0DB',
    hyperlink: '#098fe2',
    textField: '#C80000',
    switch: '#EF9A9A',
    typography: {
        fontColor: {
            primaryText: '',
            secondaryText: '',
        },
        fontFamily: ['Source Sans Pro', 'sans serif'],
        h1: {
            fontSize: '35px',
        },
        h2: {
            fontSize: '29px',
        },
        h3: {
            fontSize: '24px',
        },
        h4: {
            fontSize: '20px',
        },
        h5: {
            fontSize: '16px',
        },
        h6: {
            fontSize: '14px',
        },
        subtitle1: {
            fontSize: '16px',
        },
        subtitle2: {
            fontSize: '14px',
        },
        subtitle3: {
            fontSize: '15px',
        },
        subtitle4: {
            fontSize: '14px',
        },
        body1: {
            fontSize: '14px',
        },
        body2: {
            fontSize: '12px',
        },
        button1: {
            fontSize: '14px',
        },
        button2: {
            fontSize: '13px',
        },
        caption: {
            fontSize: '11px',
        },
        overline: {
            fontSize: '11px',
        },
        label: {
            fontSize: '14px',
        },
        dataGrid: {
            tableHeader: {
                fontSize: '12.8px',
            },
            tableBody: {
                fontSize: '14px',
                color: '#464646',
            },
            tableExpandable: {
                fontSize: '14px',
            },
        },
    },
}

export const iconsTheme = {
    home: 'fas fa-home',
    code: 'fas fa-code',
    moneyCheckAlt: 'fas fa-money-check-alt',
    exclamationCircle: 'fas fa-exclamation-circle',
    chartPie: 'fas fa-chart-pie',
    users: 'fas fa-users',
    cog: 'fas fa-cog',
    lifeRing: 'fas fa-life-ring',
    creditCard: 'fas fa-credit-card',
    calendar: 'fas fa-calendar-alt',
    briefcase: 'fas fa-briefcase',
    singleConsumer: 'fas fa-user',
    singleConsumerStyle: 'fas fa-user',
    notification: '',
    clock: '',
    star: 'fas fa-star',
    clipboard: 'fas fa-clipboard',
    cogs: 'fas fa-cogs',
    check: 'fas fa-check',
    ban: 'fas fa-ban',
    clockTwo: 'fas fa-clock',
    question: '',
    bell: 'fas fa-bell',
    exchange: 'fas fa-exchange',
    outdentBar: '',
    indentBar: '',
    bars: '',
    document: 'icon-document',
    toggleUnlock: 'icon-toggle-unlock',
    toggleLock: 'icon-toggle-lock',
    edit: 'icon-edit',
    midPermission: 'icon-mid-permission',
    sitePermission: 'icon-site-permission',
    tempPassword: 'icon-temp-password',
    trashCan: 'icon-trash-can',
    wheel: 'fas fa-cogs',
    flagged: 'icon-flagged',
    unflagged: 'icon-unflagged',
    assign: 'icon-assign',
    cross: 'icon-cross',
    inProgress: 'fontawesome-inprogress',
    win: 'fontawesome-win',
    loss: 'fontawesome-loss',
    upload: '',
    solidLock: 'fas fa-lock',
    download: 'fas fa-download',
}
